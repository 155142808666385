import { Link, navigate } from 'gatsby';
import React from 'react';
import { Button } from 'semantic-ui-react';
import Header from '../../components/Header';

import Layout from '../../components/layout';
import { useUserState } from '../../context/UserContext';

const Admin = () => {
  const { user } = useUserState();

  if (!user) {
    return (
      <Layout title="Admin">
        <Header title="Contributions" subtitle="Unauthorized access" />
        <div id="main">
          <section id="content" className="main">
            <p>You are not authorized to view this page, please <Link to="/sign-in">Sign In</Link> to continue.</p>
          </section>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Admin">
      <Header title="Administration" subtitle="Manage members and policies" />
      <div id="main">
        <section id="content" className="main">
          <Button size="large" fluid onClick={() => navigate('/admin/registrations')}>Registrations</Button>
          <br />
          <Button size="large" fluid onClick={() => navigate('/admin/contributions')}>Contributions</Button>
        </section>
      </div>
    </Layout>
  );
};

export default Admin;
